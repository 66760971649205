<template>
  <!--  顺序练习-->
  <!-- 最外部div -----------------------=======================================-->
  <div class="outermost" style="background-color: #eeeeee;">
    <!-- 全局加载界面-->
    <div v-loading.fullscreen.lock="fullscreenLoading"></div>
    <!-- 头部 ======================================== -->
    <header class="use-header">
      <!-- logo -->
      <div class="use-logo">
        <img src="../../public/static/img/logo.png" alt="" class="logo_img">
      </div>
      <!-- 考试名称 -->
      <div class="exam">
        <p class="exam_text">
          {{ ksxx.ksmc }}（练习模式）
        </p>
        <!-- 手机目录 -->
        <div class="list">
          <!--          <img src="../../public/static/img/examination/list.png" alt="" class="list_img">-->
        </div>
      </div>
    </header>
    <!-- 页面主体 ==================================== -->
    <main class="use-main">
      <!-- 左边部分 ================================ -->
      <article>
        <!--学生区域  -------------------------------->
        <div class="message">
          <!-- 学生照片 -->
          <img src="../../public/static/img/examination/Head Portraits.png" alt="" class="photo">
          <!-- 学生信息 pc端-->
          <hgroup>
            <h2>
              姓名：&nbsp;{{ xsjbxx.xm }}
            </h2>
            <h2>
              班级：&nbsp;{{ xsjbxx.bj }} 班
            </h2>
            <h2>
              准考证号：&nbsp;{{ xsjbxx.ksh }}
            </h2>
            <h2>
              考试科目：&nbsp;{{ ksxx.kmmc }}
            </h2>
          </hgroup>

          <!-- 学生信息 手机端/////////////////////-->
          <!-- <div class="hgroup_phone"> -->
          <div class="hgroup_p1">
            <img src="../../public/static/img/examination/yonghu.png" alt="">
            <span>{{ xsjbxx.xm }}&nbsp;({{ xsjbxx.bj }} 班)</span><br>
            <img src="../../public/static/img/examination/kemu.png" alt="">
            <span>{{ ksxx.kmmc }}</span>
          </div>
          <div class="hgroup_p2">
            <img src="../../public/static/img/examination/clock.png" alt="">
            <span>{{ dqsj | CountDownTime }}</span>
          </div>
          <button class="btn_2" @click="backToSelect">返 回</button>
          <!-- </div> -->
          <!-- 交卷按钮 -->
          <button class="btn" @click="backToSelect">返 回</button>
        </div>
        <!--试卷区域  --------------------------------->
        <div class="window" v-if="stxxMap.get(dqstid)!=null">
          <!-- 大题、题号、分数等 -->
          <p class="window_1_1">
            <!-- 一、选择题（单项选择） -->
          </p>
          <!-- 小题、题号、问题、分数等 -->
          <div class="window_2">
            <p class="issue" v-if="(stxxMap.get(dqstid).zhstid != undefined)"
               v-html="stxxMap.get(stxxMap.get(dqstid).zhstid).tgxx">

            </p>
            <p class="issue" v-html="dqstIndex+'、'+getStlx(stxxMap.get(dqstid).stlx).stlxmc">
            </p>
            <p class="issue" v-html="stxxMap.get(dqstid).tgxx">
            </p>
            <!--     单选题选项       -->
            <div class="check" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 1">
              <ul
                  @click="changeZd(stxxMap.get(dqstid),item.xx)"
                  :class="{check_box:true,active : checkIncludes(stxxMap.get(dqstid).zdnr,item.xx)}"
                  v-for="(item,index) in JSON.parse(stxxMap.get(dqstid).xxxx)">
                <li>
                  <input name="danxuan"
                         type="radio"
                         :value="item.xx"
                         class="check_round"
                         :key="dqstid+index"
                         :checked="stxxMap.get(dqstid).zdnr == item.xx"
                  /> &nbsp;
                  <span class="check_span" v-html="item.xx +'.'+item.nr"></span>
                </li>
              </ul>
            </div>
            <!--     多选题选项       -->
            <div class="check" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 2">
              <ul
                  @click="changeZdDx(stxxMap.get(dqstid),item.xx)"
                  :class="{check_box:true,active : checkIncludes(stxxMap.get(dqstid).zdnr,item.xx)}"
                  v-for="(item,index) in JSON.parse(stxxMap.get(dqstid).xxxx)"
              >
                <li>
                  <input name="danxuan"
                         type="checkbox"
                         v-model="stxxMap.get(dqstid).zdnr"
                         :value="item.xx"
                         @input="updateData"
                         class="check_round"/> &nbsp;
                  <span class="check_span" v-html="item.xx +'.'+item.nr"></span>
                </li>
              </ul>
            </div>

            <!--判断题选项区域-->
            <div class="check" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 3">
              <label class="check_box" :class="{active : checkIncludes(stxxMap.get(dqstid).zdnr,'A')}">
                <input :name="'answer_'"
                       type="radio"
                       value="A"
                       :key="dqstid+'A'"
                       :checked="stxxMap.get(dqstid).zdnr == 'A'"
                       class="check_round"
                       @change="changeZd(stxxMap.get(dqstid),'A')"/>
                <span class="check_span"> 正确 </span>
              </label>
              <label class="check_box" :class="{active : checkIncludes(stxxMap.get(dqstid).zdnr,'B')}">
                <input :name="'answer_'"
                       type="radio" value="B"
                       :key="dqstid+'B'"
                       :checked="stxxMap.get(dqstid).zdnr == 'B'"
                       class="check_round"
                       @change="changeZd(stxxMap.get(dqstid),'B')"/>
                <span class="check_span"> 错误 </span>
              </label>
            </div>

            <!--填空题-->
            <div class="check" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 4">
              <el-input
                  class="check_input"
                  maxlength="100"
                  v-for="(item,index) in JSON.parse(stxxMap.get(dqstid).xxxx)"
                  :name="'answer_'+stxxMap.get(dqstid).stsx"
                  :value="getTktZdnr(stxxMap.get(dqstid),index)"
                  :key="stxxMap.get(dqstid).stsx+'_'+index"
                  placeholder="请输入作答"
                  @input="(e)=>{changeInput(stxxMap.get(dqstid),e,index);changeZd(stxxMap.get(dqstid))}"
                  @paste.native.capture.prevent="handleFalse"
                  @copy.native.capture.prevent="handleFalse"
                  @cut.native.capture.prevent="handleFalse"
                  show-word-limit
              >
                <template slot="prepend">{{ index + 1 }}</template>
              </el-input>
            </div>

            <!--简答题-->
            <div class="check" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 5">
              <el-input
                  type="textarea"
                  :rows="4"
                  class="check_input"
                  maxlength="1000"
                  v-model="stxxMap.get(dqstid).zdnr"
                  @input="updateData"
                  @change="changeZd(stxxMap.get(dqstid))"
                  @paste.native.capture.prevent="handleFalse"
                  @copy.native.capture.prevent="handleFalse"
                  @cut.native.capture.prevent="handleFalse"
                  placeholder="请输入作答"
                  show-word-limit
                  resize="none">
              </el-input>
            </div>
          </div>


          <!-- 上一题 下一题 标记等辅助按钮 -->
          <div class="window_3">
            <button class="question1" @click="previous()">
              <span>上一题</span>
            </button>
            <button class="question2" @click="next()">
              <span>下一题</span>
            </button>
            <button class="question3"
                    @click="changeZd(stxxMap.get(dqstid))"
                    v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 2 && stxxMap.get(dqstid).zdnr.length > 0"
            >
              <span>提交</span>
            </button>
            <label class="flag">
              <input type="checkbox" v-model="stxxMap.get(dqstid).mark" class="flag_input">
              <span class="flag_span">标记</span>
              <img src="../../public/static/img/examination/pennant.png" alt="" class="flag_img">
            </label>
          </div>
        </div>
        <!-- 作答区域  ---------------------------------->
        <div class="answer">
          <!-- 选项区 -->
          <div class="answer_div1" v-if="showdajx">
            <!-- 题型 -->
            <p class="question_2_1">
              答案解析
            </p>
            <!-- 选项 -->
            <div class="pick_2">
              <p class="key_p2"
                 v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 1 || getStlx(stxxMap.get(dqstid).stlx).stlx == 2">
                <span>正确答案：</span> <span
                  v-for="item in JSON.parse(stxxMap.get(dqstid).bzda)">{{ item }}</span></p>
              <p class="key_p2"
                 v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 3">
                <span>正确答案：</span> <span
                  v-for="item in JSON.parse(stxxMap.get(dqstid).bzda)">{{ item | charToBoolean }}</span></p>
              <p class="key_p2" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 1"><span>我的答案： </span>
                <span>{{ stxxMap.get(dqstid).zdnr }}</span>
              </p>
              <p class="key_p2" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 2"><span>我的答案： </span>
                <span v-for="item in stxxMap.get(dqstid).zdnr">{{ item }}</span>
              </p>
              <p class="key_p2" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 3"><span>我的答案： </span>
                <span v-for="item in stxxMap.get(dqstid).zdnr">{{ item| charToBoolean }}</span>
              </p>
              <p class="key_p2" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx == 5"><span>答案解析： </span>
                <span>{{ JSON.parse(stxxMap.get(dqstid).bzda)[0] }}</span>
              </p>
              <p class="key_p2" v-if="getStlx(stxxMap.get(dqstid).stlx).stlx != 5">答案解析：暂无</p>
            </div>
          </div>
        </div>
      </article>
      <!-- 右边部分 =============================== -->
      <aside>

        <!-- 表头答题卡 -->
        <div class="card_head">
          <div class="ch_left">
            <img src="../../public/static/img/examination/card.png" alt="">
            <span>答题卡</span>
          </div>
          <div class="ch_right">
            <img src="../../public/static/img/examination/clock.png" alt="">
            <span id="times">{{ dqsj | CountDownTime }}</span>
          </div>
        </div>
        <!-- 答题卡主体 -->
        <div class="subject_2">
          <div class="subject_main">
            <div class="subject_top">
              <span class="subject_top_span1">共{{ this.stxx.length }}题</span>
            </div>
            <div class="subject_bottom">
              <div v-for="(item,index) in dtkxx" style="margin-top:15px" :key="index">
                <el-divider content-position="left">{{ item.tzmc }}</el-divider>
                <br>
                <div :id="'box'+item.stid"
                     :class="{'box':true,active:(dqstid === item.stid),mark : stxxMap.get(item.stid).mark , finish :  isEmpty(stxxMap.get(item.stid).zdnr), error : stxxMap.get(item.stid).error }"
                     v-for="(item,index) in item.stids" @click="changeSt(item)" :key="index">
                  {{ item.sort }}
                  <i></i>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- 答题卡提示信息 -->
        <div class="tishi">
          <p class="tishi_p">练题总数：<span class="tishi_p_span1">{{ lxTotal }}题</span></p>
          <p class="tishi_p">答对：<span class="tishi_p_span2">{{ ddTotal }}题</span></p>
          <p class="tishi_p">答错：<span class="tishi_p_span3">{{ dcTotal }}题</span></p>
          <p class="tishi_p">自行判断：<span class="tishi_p_span5">{{ zxpdTotal }}题</span></p>
          <p class="tishi_p">正确率：<span class="tishi_p_span4">{{ correctRate }}%</span></p>
          <div class="tishi_line"></div>
        </div>

        <!-- 练习设置 -->
        <div class="set_div">
          <!-- 标题 -->
          <div class="set_p1">练习设置</div>
          <!-- 选项1 -->
          <div class="set_option">
            <p class="set_p2_1">答对自动下一题</p>
            <div class="testswitch">
              <input class="testswitch-checkbox" id="onoffswitch" type="checkbox" v-model="ddzdxyt">
              <label class="testswitch-label" for="onoffswitch">
                <span class="testswitch-inner" data-on="" data-off=""></span>
                <span class="testswitch-switch"></span>
              </label>
            </div>
          </div>
          <!-- 选项2 -->
          <div class="set_option">
            <p class="set_p2_2">显示答案解析</p>
            <div class="testswitch">
              <input class="testswitch-checkbox" id="onoffswitch1" type="checkbox" v-model="showdajx">
              <label class="testswitch-label" for="onoffswitch1">
                <span class="testswitch-inner" data-on="" data-off=""></span>
                <span class="testswitch-switch"></span>
              </label>
            </div>
          </div>

        </div>
        <!-- 答题卡底部说明 -->
        <div class="sign">

          <div class="sign_div">
            <div class="cube1"></div>
            <span>未做</span>
          </div>
          <div class="sign_div">
            <div class="cube2"></div>
            <span>答对</span>
          </div>
          <div class="sign_div">
            <div class="cube3"></div>
            <span>标记</span>
          </div>
          <div class="sign_div">
            <div class="cube4"></div>
            <span>答错</span>
          </div>


        </div>

      </aside>
    </main>
  </div>
</template>
<style src="../../public/static/css/practice.css" scoped>

</style>
<script>

import {getTokenInfo} from "@/utils/auth";
import {handleAlert} from "@/utils/confirm";
import Vue from 'vue'
import md5 from 'js-md5';

export default {
  name: "sequentialPractice",
  data: function () {
    return {
      fullscreenLoading: true,   //全局loading
      ddzdxyt: true,             //答对是否自动下一题
      showdajx: false,            //是否显示答案解析
      stxx: [],                  //所有试题信息
      stxxMap: new Map(),        //所有试题信息Map 方便存取
      dqsj: 0,                   //当前时间
      session: null,             //会话信息
      xsjbxx: {},                //学生信息
      ksxx: {},                  //考试科目
      dtkxx: [],                 //答题卡信息
      dqstid: null,              //当前试题id
      dqstIndex: null,           //当前试题序号
      stlxMap: new Map(),        //试题类型Map stlx为 key
      stlxmcMap: new Map(),        //试题类型Map stlxmc 为 key
      stSortMap: new Map(),      //试题顺序sortMap
      lxTotal: 0,                //已练习总数
      ddTotal: 0,                //答对数量
      dcTotal: 0,                //答错数量
      zxpdTotal: 0,              //自行判断数量
      correctRate: 0,            //正确率
      securityKey: '',           //用来加密的key
      encryptionCompleteKey: '', //用来加密的key
      stslTotal: 0               //总的试题数量
    }
  },
  created() {

    let stxx;
    this.session = SessionStorage.get(SESSION_KEY_EXAM_ANSWER)
    //题库组卷

    this.$ajax.post(localStorage.getItem('ApiUrl') + '/practice/getQbankStxxPracticeMode', this.session).then((response) => {
      let resp = response.data
      if (resp.success) {
        stxx = resp.content

        this.tokenInfo = getTokenInfo()
        //判断session中是否有考试信息
        if (Tool.isEmpty(this.session) || Tool.isEmpty(stxx)) {
          this.backToSelect();
          return;
        }

        //去后台获取学生信息
        this.$ajax.post(localStorage.getItem('ApiUrl') + '/practice/initPractice', this.session).then((response) => {
          let resp = response.data
          if (resp.success) {
            let {ksxx, xsjbxx} = resp.content
            //初始化一些变量
            this.ksxx = ksxx
            this.xsjbxx = xsjbxx
          } else {
            handleAlert(resp.message, 'error')
            this.backToSelect()
          }
        })


        this.getAllStlx().then(() => {
          this.stxx = stxx
          //根据试题类型名称修正试题试题类型
          stxx = stxx.filter(item => {
            item.stlx = this.getStlxmc(item.tzmc).id + ''
            return item
          })
          // list转map方便使用
          stxx.forEach(row => {
            //初始化作答内容
            if (this.getStlx(row.stlx).stlx == 2) {
              //如果为多选题则初始化为数组
              row.zdnr = []
            } else {
              row.zdnr = null
            }
            if (this.getStlx(row.stlx).stlx != 0) {
              this.stslTotal++
            }
            this.stxxMap.set(row.stid, row)
          })

          let stIdMap = new Map()
          //初始化答题卡信息
          stxx.forEach(item => {
            if (item.stlx != 0) {
              if (stIdMap.has(item.stlx)) {
                let array = stIdMap.get(item.stlx)
                array.push(item.stid)
                stIdMap.set(item.stlx, array)
              } else {

                let array = []
                array.push(item.stid)
                stIdMap.set(item.stlx, array)
              }
            }
          })
          let arr = Array.from(stIdMap); // 二维数组

          //对数组进行排序
          arr.sort()
          let index = 1
          arr.forEach(item => {

            let sortStidArray = []

            item[1].forEach(stItem => {
              this.securityKey += stItem + ''
              sortStidArray.push({stid: stItem, sort: index})
              this.stSortMap.set(index, stItem)
              index++
            })
            let tz = {
              tzmc: this.getStlx(item[0]).stlxmc,
              stids: sortStidArray
            }

            this.dtkxx.push(tz)

          })
          //初始化到第一题
          this.encryptionCompleteKey = md5(this.securityKey).substring(0, 200)
          if (SessionStorage.get(SESSION_KEY_PRACTICE_DQSTINDEX) != null) {
            //是否有上一次答题的记录
            let compareKey = SessionStorage.get(SESSION_KEY_PRACTICE_DQSTINDEX).key
            let dqstIndex = SessionStorage.get(SESSION_KEY_PRACTICE_DQSTINDEX).value

            if (compareKey == this.encryptionCompleteKey) {
              //如果页面保存的当前试题index来源与当前页面相拥，则使用该index
              let stxx = {
                stid: this.stSortMap.get(dqstIndex),
                sort: dqstIndex
              }
              this.changeSt(this.dtkxx[0].stids[0])
              if (dqstIndex == 1) {
                return
              }
              this.$confirm('上次练习到了' + dqstIndex + '题, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
              }).then(() => {
                this.changeSt(stxx)
              }).catch(() => {
                this.changeSt(this.dtkxx[0].stids[0])
              });

            } else {
              this.changeSt(this.dtkxx[0].stids[0])
            }
          } else {
            this.changeSt(this.dtkxx[0].stids[0])
          }
        })
        //开始计时
        this.checkInterval();
        this.countInterval = setInterval(this.checkInterval, 100);

      } else {
        handleAlert(resp.message, 'error')
        this.backToSelect()
      }
      this.fullscreenLoading = false;
      this.divLoading = false
    });


  },

  methods: {
    //返回选择菜单
    backToSelect: function () {
      this.$router.push("/examDetails")
    },
    handleFalse() {
      //终止
      return false;
    },//开始倒计时循环
    checkInterval: function () {
      this.dqsj += 0.1
    },
    //获取所有试题类型
    async getAllStlx() {
      await this.$ajax.post(localStorage.getItem('ApiUrl') + '/answer/getAllStlx').then((response) => {
        let resp = response.data
        if (resp.success) {

          resp.content.forEach(item => {
            this.stlxMap.set(item.id + '', item)
          })
          resp.content.forEach(item => {
            this.stlxmcMap.set(item.stlxmc + '', item)
          })
        } else {
          handleAlert(resp.message, 'error')
          this.backToSelect()
        }
      });
    },
    //获取试题类型名称
    getStlx: function (stlxid, default_name = '') {
      return this.stlxMap.get(stlxid)
    },
    //获取试题类型名称
    getStlxmc: function (stlxmc, default_name = '') {
      return this.stlxmcMap.get(stlxmc)
    },
    changeSt(stxx) {
      this.dqstid = stxx.stid
      this.dqstidMove(this.dqstid);
      this.dqstIndex = stxx.sort
      //点击切换试题
    },

    previous() {

      //上一题
      if (this.dqstIndex - 1 < 1) {
        this.$message('已经是第一题！');
        return
      }
      this.dqstIndex--
      this.dqstid = this.stSortMap.get(this.dqstIndex)
    },
    next() {
      //下一题
      if (this.dqstIndex + 1 > this.stslTotal) {
        this.$message('已经是最后一题！');
        return
      }
      this.dqstIndex++
      this.dqstid = this.stSortMap.get(this.dqstIndex);
      this.dqstidMove(this.dqstid);
    },

    dqstidMove(value) {
      //刚进页面有可能还未加载出dom
      if (document.querySelector("#box" + value) != null) {
        document.querySelector("#box" + value).scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block: "end"
        });
      }
    },
    updateData() {
      //更新渲染
      this.$forceUpdate()
    },
    changeInput(stItem,e,index) {
      if(!stItem.zdnr){
        stItem.zdnr = JSON.parse(stItem.xxxx)
      }
      stItem.zdnr[index] = e
    },
    changeZd(stxx, zdxx) {
      switch (this.getStlx(stxx.stlx).stlx) {
        case '1' : {
          stxx.zdnr = zdxx
          Vue.set(this.stxxMap, stxx.stid, stxx)
          //单选题逻辑
          if (zdxx == JSON.parse(stxx.bzda)[0]) {
            //关闭答案解析
            this.showdajx = false
            this.stxxMap.get(stxx.stid).error = false
            if (this.ddzdxyt) {
              this.next()
            }
          } else {
            this.showdajx = true
            this.stxxMap.get(stxx.stid).error = true
          }
          this.$forceUpdate();
          break
        }
        case '2' : {
          //多选题逻辑
          let bzdaTotal = 0;
          let bzdaMap = new Map();
          JSON.parse(stxx.bzda).forEach(item => {
            bzdaMap.set(item, item)
          })

          stxx.zdnr.forEach(item => {
            if (bzdaMap.has(item)) {
              bzdaTotal++
            } else {
              bzdaTotal--
            }
          })
          if (JSON.parse(stxx.bzda).length === bzdaTotal) {
            //关闭答案解析
            this.showdajx = false
            this.stxxMap.get(stxx.stid).error = false
            if (this.ddzdxyt) {
              this.next()
            }

          } else {
            this.showdajx = true
            this.stxxMap.get(stxx.stid).error = true
          }
          this.$forceUpdate();
          break
        }
        case '3' : {
          stxx.zdnr = zdxx
          Vue.set(this.stxxMap, stxx.stid, stxx)
          //判断题逻辑
          if (stxx.zdnr == JSON.parse(stxx.bzda)[0]) {
            //关闭答案解析
            this.showdajx = false
            this.stxxMap.get(stxx.stid).error = false
            if (this.ddzdxyt) {
              this.next()
            }
          } else {
            this.showdajx = true
            this.stxxMap.get(stxx.stid).error = true
          }
          this.$forceUpdate();
          break
        }
        case '4' : {
          //简答题逻辑

          break
        }
      }
      //计算各种total
      this.calculateTotal()
    },
    changeZdDx(stItem, xx) {
      if (this.checkIncludes(stItem.zdnr, xx)) {
        stItem.zdnr = stItem.zdnr.filter(item => {
          return item != xx
        })
      } else {
        stItem.zdnr.push(xx)
      }
    },
    isEmpty(zdnr) {
      if (this.isArrayFn(zdnr)) {
        if (zdnr.length > 0) {
          return true
        }
      } else {
        if (zdnr != null && zdnr.trim() != '') {
          return true
        }
      }
      return false
    },
    isArrayFn(value) {
      if (typeof Array.isArray === "function") {
        return Array.isArray(value);
      } else {
        return Object.prototype.toString.call(value) === "[object Array]";
      }
    },
    calculateTotal() {
      let dc = 0;
      let dd = 0;
      let zp = 0;
      this.stxx.forEach(item => {
        if (this.getStlx(item.stlx).stlx == 1 ||
            this.getStlx(item.stlx).stlx == 2 ||
            this.getStlx(item.stlx).stlx == 3) {

          if (item.error == true) {
            dc++
          }
          if (this.isEmpty(item.zdnr)) {
            dd++
          }
        } else {
          if (this.isEmpty(item.zdnr)) {
            zp++
          }
        }
      })
      this.zxpdTotal = zp
      this.dcTotal = dc
      this.ddTotal = dd - dc
      this.lxTotal = this.ddTotal + dc + zp
      if ((this.lxTotal - zp) != 0) {
        this.correctRate = (this.ddTotal / (this.lxTotal - zp)).toFixed(2) * 100;
      } else {
        this.correctRate = 0.00
      }
    },//检查是否包含
    checkIncludes: function (str, obj) {

      if (str == null || str == '') {
        return false
      }

      if (str.includes(obj)) {
        return true
      }

      return false
    },
    //获取填空题内容
    getTktZdnr(stItem, index) {
      let zdnr = stItem.zdnr;
      if (zdnr == null || zdnr == '') {
        return ''
      }

      if (typeof zdnr == 'string') {
        try {
          return JSON.parse(zdnr)[index]
        } catch (e) {
          return '';
        }
      }
      return zdnr[index]
    }
  },
  filters: {
    CountDownTime(item) {
      let i_item = parseInt(item);
      let hour = parseInt(i_item / 3600)
      i_item = item % 3600
      let minute = parseInt(i_item / 60)
      let second = parseInt(item % 60)

      if (hour > 0) {
        return `${hour} : ${minute} : ${second}`
      } else {
        return `${minute} : ${second}`
      }
    },
    charToBoolean(text) {
      if (text == 'A') {
        return '正确'
      } else {
        return '错误'
      }
    }
  },
  computed: {},
  watch: {
    dqstIndex(newValue, oldValue) {
      let data = {
        key: this.encryptionCompleteKey,
        value: newValue
      }
      SessionStorage.set(SESSION_KEY_PRACTICE_DQSTINDEX, data)
      this.$nextTick(() => {
        this.commonsVariable.MathQueueByClass('window');//传入组件id，让组件被MathJax渲染
      })
    }
  }
}
</script>

